import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Button } from "@mui/material";

interface SearchButtonProps {
  onClickShowSearhComponent: () => void;
}

export const SearchButton: React.FC<SearchButtonProps> = ({
  onClickShowSearhComponent,
}) => {
  return (
    <Paper
      onClick={onClickShowSearhComponent}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      {/* <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="관심 주점을 검색해봐!"
        inputProps={{ "aria-label": "관심 주점을 검색해봐!" }}
      /> */}
      <Button
        sx={{ ml: 1, flex: 1, justifyContent: "left", cursor: "pointer" }}
        disabled
      >
        관심 주점을 검색해봐!
      </Button>
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {/* <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
};
