import { Button } from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
// import { Pagination } from "swiper/modules";
import InstagramIcon from "@mui/icons-material/Instagram";
import ShareIcon from "@mui/icons-material/Share";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  NoticeData,
  Pin,
  bar_pins_day1,
  bar_pins_day2,
  bar_pins_day3,
} from "../../constant/pins";
import MyContext from "../../contexts/MyContext";
import { db } from "../../firebase";

import "./swiperStyles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "./styles.css";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

interface NoticeType {
  id: string;
  title: string;
  content: string;
  date: Date;
}

interface FirebaseJoojumType {
  id?: string;
  department: string;
  instagram: string;
  like: number;
  notices: NoticeType[]; // 업데이트된 notices 타입
}

interface NoticeProps {
  focusJoojum: { [key: string]: Pin };
}

export const Notice: React.FC<NoticeProps> = ({ focusJoojum }) => {
  const { setBottomSheetStatus, pinStatus } = useContext(MyContext);
  const index = 1;
  const imageCount = NoticeData[pinStatus]?.imageNumber || 0;
  const [firebaseJoojum, setFirebaseJoojum] =
    useState<FirebaseJoojumType | null>(null); // 상태 초기화

  // 추가된 상태 관리
  const [newTitle, setNewTitle] = useState("");
  const [newContent, setNewContent] = useState("");
  const [showForm, setShowForm] = useState(false); // 폼 표시 여부 상태 추가

  useEffect(() => {
    const collectionRef = collection(db, "Joojum");
    // 실시간 리스너 설정
    const unsubscribe = onSnapshot(collectionRef, async (querySnapshot) => {
      const joojumDocuments: FirebaseJoojumType[] = [];
      for (const doc of querySnapshot.docs) {
        if (doc.exists()) {
          const data = doc.data() as Omit<FirebaseJoojumType, "notices">; // notices 제외한 타입으로 캐스팅
          const noticesRef = collection(db, "Joojum", doc.id, "notice");
          const noticesSnapshot = await getDocs(noticesRef);
          const notices = noticesSnapshot.docs.map((noticeDoc) => {
            const noticeData = noticeDoc.data();
            return {
              ...noticeData,
              id: noticeDoc.id,
              date: noticeData.date.toDate(),
            } as NoticeType;
          });

          joojumDocuments.push({
            ...data,
            id: doc.id,
            notices: notices,
          });
        }
      }

      const filteredList = joojumDocuments.filter(
        (item) => item.department === pinStatus
      );

      setFirebaseJoojum(filteredList[0] || null);
    });

    // 컴포넌트가 언마운트되면 실시간 리스너 해제
    return () => {
      unsubscribe();
    };
  }, [pinStatus]);

  // 수정된 함수: 새로운 공지사항 추가 함수
  const handleAddNotice = async () => {
    const collectionName = "Joojum"; // 메인 컬렉션 이름
    const docId = firebaseJoojum?.id; // 현재 문서의 ID 사용
    const subCollectionName = "notice"; // 서브 컬렉션 이름

    if (docId) {
      // 메인 문서의 참조 생성
      const mainDocRef = doc(db, collectionName, docId);
      // notice 서브 컬렉션의 새 문서 참조 생성
      const subCollectionRef = collection(mainDocRef, subCollectionName);
      const newNoticeDocRef = doc(subCollectionRef); // 새로운 문서 ID 자동 생성

      const payload = {
        title: newTitle, // 새 공지사항 제목
        content: newContent, // 새 공지사항 내용
        date: new Date(), // 현재 날짜와 시간 -> 공지가 시간 순으로 올라갈 수 있게끔
      };

      try {
        // 새 공지사항 문서 생성
        await setDoc(newNoticeDocRef, payload);
        //console.log("성공");

        if (firebaseJoojum) {
          setFirebaseJoojum({
            ...firebaseJoojum,
            notices: [
              {
                ...payload,
                id: newNoticeDocRef.id,
                date: new Date(payload.date),
              },
              ...firebaseJoojum.notices,
            ],

            //최신 공지 위에 나오게 하기 위함
          });
        }

        setNewTitle(""); // 입력 폼 초기화
        setNewContent(""); // 입력 폼 초기화
        setShowForm(false); // 폼 숨기기
      } catch (error) {
        console.error("실패: ", error);
      }
    }
  };

  // 수정 공지사항 폼 제출
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddNotice();
  };

  const handleDeleteNotice = async (noticeId: string) => {
    const collectionName = "Joojum";
    const docId = firebaseJoojum?.id;
    const subCollectionName = "notice";

    if (docId) {
      const noticeDocRef = doc(
        db,
        collectionName,
        docId,
        subCollectionName,
        noticeId
      );

      try {
        await deleteDoc(noticeDocRef);
        //console.log("삭제 성공");

        // 상태 업데이트
        if (firebaseJoojum) {
          setFirebaseJoojum({
            ...firebaseJoojum,
            notices: firebaseJoojum.notices.filter(
              (notice) => notice.id !== noticeId
            ),
          });
        }
      } catch (error) {
        console.error("실패", error);
      }
    }
  };

  const handleClick = () => {
    window.open(
      "https://forms.gle/C2DrJHAKGyo9DmE17",
      "_blank",
      "noopener,noreferrer"
    );
  };

  //공지사항 MUI
  const card = (
    <React.Fragment>
      <CardContent>
        <CardActions>
          {/* 관리자만 이 버튼이 보이게끔 해야할듯 */}
          <Button size="small" onClick={() => setShowForm(true)}>
            공지 추가하기
          </Button>
          {/* 추가된 폼 표시 조건 */}
          {showForm && (
            <form onSubmit={handleSubmit}>
              <div>
                <label>제목:</label>
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>내용:</label>
                <textarea
                  value={newContent}
                  onChange={(e) => setNewContent(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" size="small">
                공지 추가하기
              </Button>
              <Button
                type="submit"
                size="small"
                onClick={() => setShowForm(false)}
              >
                취소
              </Button>
            </form>
          )}
        </CardActions>
        <h1 color="grey" style={{ fontWeight: "bold", fontSize: "40px" }}>
          🍺 공지사항 🍺
        </h1>
        <hr></hr>

        {firebaseJoojum?.notices
          ?.sort((a, b) => b.date.getTime() - a.date.getTime())
          .map((notice: NoticeType, index: number) => (
            <div key={index}>
              <Typography
                variant="h5"
                color="black"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                {notice.title}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {notice.content}
              </Typography>
              <Button
                type="button"
                size="small"
                onClick={() => handleDeleteNotice(notice.id)}
              >
                삭제
              </Button>

              <hr></hr>
            </div>
          ))}
      </CardContent>
    </React.Fragment>
  );

  const handleShare = () => {
    const allJoojum = {
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };

    const currentUrl = window.location.href; //현재 url
    const domainUrl = currentUrl.split("/").slice(0, 3).join("/") + "/"; // 도메인 url
    const pathSuffix = `joojum?department=${encodeURIComponent(pinStatus)}`; //현재 url의 뒷부분
    const shareUrl = `${domainUrl}${pathSuffix}`;

    if (navigator.share) {
      navigator
        .share({
          title: `${allJoojum[pinStatus].name}`,
          // text: "",
          url: shareUrl,
        })
        .then(() => console.log("공유성공"))
        .catch((error) => console.log("공유실패", error));
    } else {
      // 카카오톡 공유
      if (window.Kakao) {
        window.Kakao.Link.sendDefault(
          {
            objectType: "feed",
            content: {
              title: `${allJoojum[pinStatus].name}`,
              description: `${allJoojum[pinStatus].name} 여기 ㄱㄱ?`,
              imageUrl: shareUrl,
              link: {
                mobileWebUrl: shareUrl,
                webUrl: shareUrl,
              },
            },
            buttons: [
              {
                title: "웹으로 보기",
                link: {
                  mobileWebUrl: shareUrl,
                  webUrl: shareUrl,
                },
              },
            ],
          },
          (result: any) => {
            console.log("카카오톡 공유 성공", result);
          },
          (error: any) => {
            console.log("카카오톡 공유 실패", error);
          }
        );
      }
    }
  };
  return (
    <div>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {NoticeData[pinStatus] && NoticeData[pinStatus]?.imageNumber >= 1 ? (
          Array.from({ length: imageCount }, (_, index) => (
            <SwiperSlide key={index}>
              <img
                src={`${process.env.PUBLIC_URL}/joojum/image/${pinStatus}/Page${index + 1}.jpeg`}
                alt={`Image ${index + 1}`}
                style={{ width: "90%", height: "auto" }}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide key={index}>
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/service-advertise.png`}
                alt={`Image ${index + 1}`}
                style={{ width: "90%", height: "auto" }}
              />
            </div>
          </SwiperSlide>
        )}

        {/* https://forms.gle/C2DrJHAKGyo9DmE17 */}
      </Swiper>
      <br></br>
      {/* {firebaseJoojum?.id && (
        <LikeAndShareButton
          like={firebaseJoojum.like}
          docId={firebaseJoojum.id}
        />
      )} */}
      <Button
        onClick={handleShare}
        variant="contained"
        startIcon={<ShareIcon style={{ margin: "5px" }} />}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        친구에게 주점 같이 가자고 하기
      </Button>
      {focusJoojum[pinStatus] && focusJoojum[pinStatus].link && (
        <Button
          variant="contained"
          color="primary"
          href={focusJoojum[pinStatus].link || ""}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<InstagramIcon />}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            textTransform: "none",
            backgroundColor: "#f03c4c",
            width: "auto",
            padding: "8px 16px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          인스타 바로가기!
        </Button>
      )}
      {/* {firebaseJoojum?.instagram && (
        <Button
          variant="contained"
          color="primary"
          href={firebaseJoojum.instagram}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<InstagramIcon />}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            textTransform: "none",
            backgroundColor: "#f03c4c",
            width: "auto",
            padding: "8px 16px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          인스타 보러가기!
        </Button>
      )} */}
      {/* <br></br>
      <Box key={index} sx={{ minWidth: 275 }}>
        <Card variant="outlined">{card}</Card>
      </Box> */}
    </div>
  );
};
