import styled from "styled-components";

export const MapContainer = styled.div`
  position: relative;
`;

export const InfoButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 4;
`;

export const InfoContainer = styled.div`
  max-width: 200px;
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  line-height: 19px;
  font-size: 15px;
  font-weight: 600;
  color: #242424;
`;

export const InfoButtonBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow-x: scroll;
  max-width: 450px;
`;

export const InfoBarContainer = styled.div<InfoButtonDayProps>`
  /* max-width: 200px; */
  min-width: 90px;
  /* position: absolute;
  top: 15px;
  left: 175px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  line-height: 19px;
  font-size: 15px;
  font-weight: 600;
  color: #242424;
  margin-right: 5px;
  background-color: ${(props: any) => (props.active ? "#EF3A4A" : "white")};
`;

interface InfoButtonDayProps {
  active?: boolean;
}

export const InfoButtonDay1 = styled(InfoBarContainer)`
  top: 15px;
  left: 245px;
`;

export const InfoButtonDay2 = styled(InfoBarContainer)<InfoButtonDayProps>`
  top: 15px;
  left: 325px;
`;

export const InfoButtonDay3 = styled(InfoBarContainer)<InfoButtonDayProps>`
  top: 15px;
  left: 405px;
`;
export const ImgIcon = styled.img`
  object-fit: cover;
  object-fit: cover;
  width: 12px;
  margin-right: 6px;
`;
