export interface Pin {
  lat: number;
  lng: number;
  name: string;
  status: string;
  link?: string;
  day?: number;
  location?: string;
  contact?: boolean;
}

export const NoticeData: any = {
  KoreanLanguageAndLiterature: {
    imageNumber: 8,
  },
  ChineseLiterature: {
    imageNumber: 2,
  },
  EnglishLanguageAndLiterature: {
    imageNumber: 3,
  },
  MechanicalEngineering: {
    imageNumber: 3,
  },
  ConvergenceEnergy: {
    imageNumber: 3,
  },
  FrenchLanguageAndLiterature: {
    imageNumber: 4,
  },
  KoreanSocietyResearchAssociation: {
    imageNumber: 3,
  },
  ChemicalAndBiologicalEngineering: {
    imageNumber: 3,
  },
  KoreanHistory: {
    imageNumber: 1,
  },
  CyberDefense: {
    imageNumber: 1,
  },
  DSM: {
    imageNumber: 3,
  },
  HomeEducation: {
    imageNumber: 5,
  },
  VolunteerGroup: {
    imageNumber: 3,
  },
  cloZ: {
    imageNumber: 1,
  },
  SmartSecurity: {
    imageNumber: 4,
  },
  FoodResourceEconomics: {
    imageNumber: 1,
  },
  SoulMates: {
    imageNumber: 2,
  },
  PhysicalEducation: {
    imageNumber: 4,
  },
  InternationalAndKoreanStudies: {
    imageNumber: 6,
  },
  Chemistry: {
    imageNumber: 4,
  },
  NextGenerationCommunications: {
    imageNumber: 7,
  },
  JapaneseLanguageAndLiterature: {
    imageNumber: 3,
  },
  Statistics20: {
    imageNumber: 3,
  },

  Linguistics: {
    imageNumber: 4,
  },
  IndustrialManagement: {
    imageNumber: 6,
  },
  GeographyEducation: {
    imageNumber: 6,
  },
  Physics: {
    imageNumber: 6,
  },
  German: {
    imageNumber: 7,
  },
  Design: {
    imageNumber: 3,
  },
  Philosophy: {
    imageNumber: 3,
  },
  HealthPolicy: {
    imageNumber: 9,
  },

  //농구코트
  Chinese: {
    imageNumber: 2,
  },
  SmartMobility: {
    imageNumber: 5,
  },
  Psychology: {
    imageNumber: 6,
  },
  SooHoo: {
    imageNumber: 5,
  },

  //국제관
  InformationCollege: {
    imageNumber: 2,
  },

  //문과대 서관 앞
  LiberalArts: {
    imageNumber: 3,
  },
  HwagooHoe: {
    imageNumber: 4,
  },
  FoodEngineering: {
    imageNumber: 6,
  },

  MaterialScience: {
    imageNumber: 6,
  },
  EnvironmentalEcology: {
    imageNumber: 10,
  },

  History: {
    imageNumber: 3,
  },
  KUFit: {
    imageNumber: 5,
  },
  Misdirection: {
    imageNumber: 3,
  },
  ArchitectureAndEnvironmentalEngineering: {
    imageNumber: 2,
  },
  Nursing: {
    imageNumber: 4,
  },
  SommelierClub: {
    imageNumber: 2,
  },
  SunshineVillege: {
    imageNumber: 10,
  },
  KoreanEducation: {
    imageNumber: 3,
  },
  EqoosClub: {
    imageNumber: 2,
  },
  HUBand: {
    imageNumber: 1,
  },
  HealthAndEnvironmentalScience: {
    imageNumber: 6,
  },
  Sociology: {
    imageNumber: 5,
  },

  JASS: {
    imageNumber: 3,
  },
  Spanish: {
    imageNumber: 9,
  },

  Russian: {
    imageNumber: 3,
  },
  TaekwondoClub: {
    imageNumber: 4,
  },
  EarthAndEnvironmentalSciences: {
    imageNumber: 5,
  },

  Architecture: {
    imageNumber: 4,
  },

  AmartureSoccer: {
    imageNumber: 5,
  },

  Mathematics: {
    imageNumber: 4,
  },
};

export const pins: Record<string, Pin> = {
  // 인문캠
  Minju: { lat: 37.587067, lng: 127.032055, name: "민주광장", status: "Super" },
  Center: { lat: 37.588529, lng: 127.03377, name: "중앙광장", status: "Cool" },
  Inmun_basket: {
    lat: 37.5873443,
    lng: 127.0330366,
    name: "인문계농구코트",
    status: "Cool",
  },
  InmunSocial_front: {
    lat: 37.5876031,
    lng: 127.0313914,
    name: "인문사회관 앞/자유마루",
    status: "Cool",
  },
  International: {
    lat: 37.5880154,
    lng: 127.0310206,
    name: "국제관 앞",
    status: "Cool",
  },
  Stud_back: {
    lat: 37.5866806,
    lng: 127.03254,
    name: "학생회관 뒤 원형극장",
    status: "Cool",
  },
  // 이캠
  Hana: { lat: 37.584851, lng: 127.025972, name: "하나스퀘어", status: "Cool" },
  Novel: { lat: 37.583577, lng: 127.02815, name: "노벨광장", status: "Cool" },
  Aegineung_basket: {
    lat: 37.5827944,
    lng: 127.0274422,
    name: "애기능 농구코트",
    status: "Cool",
  },
  //etc
  Nockji: {
    lat: 37.591473,
    lng: 127.025099,
    name: "녹지운동장",
    status: "Cool",
  },
  Biz: { lat: 37.5902808, lng: 127.0348337, name: "경영대", status: "Cool" },
  Jeonghu: {
    lat: 37.5868212,
    lng: 127.0299084,
    name: "정경대 후문",
    status: "Cool",
  },
  chamsal: {
    lat: 37.5849317,
    lng: 127.0295486,
    name: "참살이길",
    status: "Cool",
  },
};

export const bar_pins_day1: Record<string, Pin> = {
  //민주광장
  KoreanHistory: {
    lat: 37.587351,
    lng: 127.031275,
    name: "한국사학과 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/koreauniv_koreanhistory/",
  },
  CyberDefense: {
    lat: 37.587409,
    lng: 127.031395,
    name: "사이버국방학과 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/cydf_secret/",
  },
  DSM: {
    lat: 37.587253,
    lng: 127.031382,
    name: "DSM 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_dsm/",
  },
  HomeEducation: {
    lat: 37.587179,
    lng: 127.031511,
    name: "가정교육과 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_heed/",
  },
  VolunteerGroup: {
    lat: 37.587541,
    lng: 127.031559,
    name: "자진근로반 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/kuvolunteer/",
  },
  EnglishLanguageAndLiterature: {
    lat: 37.587619,
    lng: 127.031672,
    name: "영어영문학과 주점",
    link: "https://www.instagram.com/koreauniv_ell/",
    day: 1,
    status: "icon",
    location: "민광",
  },
  cloZ: {
    lat: 37.587474,
    lng: 127.031672,
    name: "clo.Z 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/clo___z/",
    contact: true,
  },
  FoodResourceEconomics: {
    lat: 37.587545,
    lng: 127.031785,
    name: "식품자원경제학과 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_freconomics/",
  },
  SmartSecurity: {
    lat: 37.587396,
    lng: 127.031752,
    name: "스마트보안학과 주점",
    day: 1,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_smartsecurity/",
  },

  //농구코트
  ChemicalAndBiologicalEngineering: {
    lat: 37.5873784,
    lng: 127.033214,
    name: "화공생명공학과 주점",
    day: 1,
    link: "https://www.instagram.com/ku_cbe_ziya/",
    status: "icon",
    location: "농코",
    contact: true,
  },
  MilitaryTrainingCorps: {
    lat: 37.5872687,
    lng: 127.033214,
    name: "학생군사교육단 주점",
    day: 1,
    status: "Hot",
    location: "농코",
  },
  ChineseLiterature: {
    lat: 37.5872066,
    lng: 127.0331696,
    name: "한문학과 주점",
    link: "https://www.instagram.com/neverlose_kuhanm/",
    day: 1,
    status: "icon",
    location: "농코",
  },
  Bio: {
    lat: 37.5873186,
    lng: 127.0329112,
    name: "바이오의공학과 소모임 주점",
    day: 1,
    status: "Hot",
    location: "농코",
  },

  KoreanLanguageAndLiterature: {
    lat: 37.5873784,
    lng: 127.0329835,
    name: "국어국문학과 주점",
    day: 1,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/ku_kll_1946/",
  },
  Statistics20: {
    lat: 37.587474,
    lng: 127.0331565,
    name: "통계학과 20학번 주점",
    day: 1,
    status: "Hot",
    location: "농코",
    link: "https://www.instagram.com/kustat6/",
  },
  SoulMates: {
    lat: 37.5873186,
    lng: 127.0331565,
    name: "소울메이트 주점",
    day: 1,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/kusoulmate/",
  },

  //원형극장
  PhysicalEducation: {
    lat: 37.5866898, // 위도 값을 적절히 설정해주세요.
    lng: 127.0326038, // 경도 값을 적절히 설정해주세요.
    name: "체육교육과 주점",
    day: 1,
    status: "icon",
    location: "원형",
    link: "https://www.instagram.com/ku_physicaledu/",
  },

  //국제관 앞

  KUBOX: {
    lat: 37.5877967,
    lng: 127.0308156,
    name: "KUBOX 주점",
    day: 1,
    status: "Hot",
    location: "국제관",
  },

  FrenchLanguageAndLiterature: {
    lat: 37.5879537,
    lng: 127.0309926,
    name: "불어불문학과 주점",
    day: 1,
    status: "icon",
    location: "국제관",
    link: "https://www.instagram.com/ku.francais/",
  },

  //문과대 서관 앞
  InternationalAndKoreanStudies: {
    lat: 37.5881543,
    lng: 127.0319721,
    name: "국제&글한융 연합 주점",
    day: 1,
    status: "icon",
    location: "서관",
    link: "https://www.instagram.com/p/C65JDejPf6A/",
  },

  //애기능 농구코드
  NextGenerationCommunications: {
    lat: 37.582744,
    lng: 127.027356,
    name: "차세대통신학과 주점",
    day: 1,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/koreauniv_kuce/",
  },
  KoreanSocietyResearchAssociation: {
    lat: 37.5826768,
    lng: 127.0275211,
    name: "한국사회연구회 주점",
    day: 1,
    status: "Hot",
    location: "애농코",
  },
  JapaneseLanguageAndLiterature: {
    lat: 37.5827098,
    lng: 127.0275801,
    name: "일어일문학과 주점",
    day: 1,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/kujapan1983/",
  },
  DolBit: {
    lat: 37.5827788,
    lng: 127.0274879,
    name: "돌빛",
    day: 1,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/ku_dolbit1983/",
  },

  //하나스퀘어
  Chemistry: {
    lat: 37.5850251,
    lng: 127.0262724,
    name: "화학과 주점",
    day: 1,
    status: "icon",
    location: "하스",
    link: "https://www.instagram.com/koreauniv.chem/",
  },
};

export const bar_pins_day2: Record<string, Pin> = {
  // 국제관앞
  Weightlifting: {
    lat: 37.5866898, // 위도 값을 적절히 설정해주세요.
    lng: 127.0326038, // 경도 값을 적절히 설정해주세요.
    name: "고려대학교 역도부 주점",
    day: 2,
    status: "Hot",
    location: "국제관",
    link: "https://www.instagram.com/ku_weightlifters/",
  },
  //민주 광장
  German: {
    lat: 37.587179,
    lng: 127.031511,
    name: "독어독문학과 주점",
    day: 2,
    status: "cool",
    location: "민광",
    link: "https://www.instagram.com/koreauniv.germanistik/",
  },
  Design: {
    lat: 37.587253,
    lng: 127.031382,
    name: "디자인조형학부 주점",
    day: 2,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/koreauniv_artndesign/",
  },
  Linguistics: {
    lat: 37.587351,
    lng: 127.031275,
    name: "언어학과 주점",
    day: 2,
    status: "hot",
    location: "민광",
    link: "https://www.instagram.com/ku_linguistics/",
  },
  HealthPolicy: {
    lat: 37.587619,
    lng: 127.031672,
    name: "보건정책관리학부 주점",
    day: 2,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/koreauniv_hpm/",
  },
  IndustrialManagement: {
    lat: 37.587541,
    lng: 127.031559,
    name: "산업경영공학 주점",
    day: 2,
    status: "cool",
    location: "민광",
    link: "https://www.instagram.com/koreauniv_ime_official/",
  },
  Physics: {
    lat: 37.587396,
    lng: 127.031752,
    name: "물리학과 주점",
    day: 2,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/koreauniv_physics/",
  },
  GeographyEducation: {
    lat: 37.587545,
    lng: 127.031785,
    name: "지리교육과 주점",
    day: 2,
    status: "hot",
    location: "민광",
    link: "https://www.instagram.com/ku._.geo/",
  },
  Philosophy: {
    lat: 37.587409,
    lng: 127.031395,
    name: "철학과 주점",
    day: 2,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_philosophy_1946/",
  },

  //농구코트
  Chinese: {
    // lat: 37.5873186,
    // lng: 127.0329112,
    lat: 37.5873784,
    lng: 127.033214,
    name: "중어중문학과 주점",
    day: 2,
    status: "hot",
    location: "민광",
    link: "https://www.instagram.com/kuchinese_1972/",
  },
  KCC: {
    // lat: 37.5873784,
    // lng: 127.0329835,
    lat: 37.5872687,
    lng: 127.033214,
    name: "KCC 주점",
    day: 2,
    status: "Hot",
    location: "민광",
    link: "https://www.instagram.com/kcc_headquarter/",
  },
  SmartMobility: {
    // lat: 37.587474,
    // lng: 127.0331565,
    lat: 37.5872066,
    lng: 127.0331696,
    name: "스마트모빌리티학부 주점",
    day: 2,
    status: "Hot",
    location: "민광",
    link: "https://www.instagram.com/kusm_official/",
  },
  Psychology: {
    // lat: 37.5872396,
    // lng: 127.0329665,
    lat: 37.5873186,
    lng: 127.0331565,
    name: "심리학부 주점",
    day: 2,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku.psy.official_sims/",
  },
  ConvergenceEnergy: {
    // lat: 37.5871633,
    lat: 37.587396,
    lng: 127.0331123,
    // lat: 37.587474,
    // lng: 127.0331565,
    // lng: 127.031752,

    name: "융합에너지공학과 주점",
    day: 2,
    status: "icon",
    location: "농구코트",
    link: "https://www.instagram.com/korea_univ_iee/",
  },
  FolkMusic: {
    // lat: 37.5872066,
    // lng: 127.0331696,
    lat: 37.587474,
    lng: 127.0331565,
    name: "민담X야아악 주점",
    day: 2,
    status: "Hot",
    location: "농구코트",
    link: "https://www.instagram.com/mindam_ak7/",
  },

  SooHoo: {
    // lat: 37.5872687,
    // lng: 127.033214,
    lat: 37.5873784,
    lng: 127.0329835,
    name: "수호회 주점",
    day: 2,
    status: "icon",
    location: "농구코트",
    link: "https://www.instagram.com/swimmingtiger_ku/",
  },

  //국제관 앞

  BiomedicalEngineering: {
    lat: 37.5877967,
    lng: 127.0308156,
    name: "바이오의공학부 주점",
    day: 2,
    status: "Hot",
    location: "국제관",
    link: "https://www.instagram.com/kubme_/",
  },
  InformationCollege: {
    lat: 37.5879537,
    lng: 127.0309926,
    name: "정보대학 주점",
    day: 2,
    status: "icon",
    location: "국제관",
    link: "https://www.instagram.com/kuci_canvas/",
  },

  LiberalArts: {
    lat: 37.5883255,
    lng: 127.0317257,
    name: "자유전공학부 주점",
    day: 2,
    status: "icon",
    location: "문과대 서관 앞",
    link: "https://www.instagram.com/kusis_2009/",
  },
  HwagooHoe: {
    lat: 37.5885547,
    lng: 127.0319964,
    name: "화구회 주점",
    day: 2,
    status: "Hot",
    location: "문과대 서관 앞",
    link: "https://www.instagram.com/hwaku_korea.univ/",
  },

  // //애기능 농구코드
  // NextGenerationCommunications: {
  //   lat: 37.582744,
  //   lng: 127.027356,
  //   name: "차세대통신학과 주점",
  //   day: 1,
  //   status: "icon",
  //   location: "애농코",
  //   link: "https://www.instagram.com/koreauniv_kuce/",
  // },
  // KoreanSocietyResearchAssociation: {
  //   lat: 37.5826768,
  //   lng: 127.0275211,
  //   name: "한국사회연구회 주점",
  //   day: 1,
  //   status: "Hot",
  //   location: "애농코",
  // },
  // JapaneseLanguageAndLiterature: {
  //   lat: 37.5827098,
  //   lng: 127.0275801,
  //   name: "일어일문학과 주점",
  //   day: 1,
  //   status: "icon",
  //   location: "애농코",
  //   link: "https://www.instagram.com/kujapan1983/",
  // },
  // DolBit: {
  //   lat: 37.5827788,
  //   lng: 127.0274879,
  //   name: "돌빛",
  //   day: 1,
  //   status: "icon",
  //   location: "애농코",
  //   link: "https://www.instagram.com/ku_dolbit1983/",
  // },

  // //하나스퀘어
  // Chemistry: {
  //   lat: 37.5850251,
  //   lng: 127.0262724,
  //   name: "화학과 주점",
  //   day: 1,
  //   status: "icon",
  //   location: "하스",
  //   link: "https://www.instagram.com/koreauniv.chem/",
  // },

  //하스
  FoodEngineering: {
    lat: 37.5850251,
    lng: 127.0262724,
    name: "식품공학과 주점",
    day: 2,
    status: "icon",
    location: "하스",
    link: "https://www.instagram.com/ku_fbt_21/",
  },

  //애기능농구코트
  MaterialScience: {
    lat: 37.5827098,
    lng: 127.0275801,
    name: "신소재공학부 주점",
    day: 2,
    status: "icon",
    location: "국제관",
    link: "https://www.instagram.com/ku_mse_1996/",
  },

  EnvironmentalEcology: {
    lat: 37.5827788,
    lng: 127.0274879,
    name: "환경생태공학부 주점",
    day: 2,
    status: "icon",
    location: "국제관",
    link: "https://www.instagram.com/ku_eco.official/",
  },

  Biotechnology: {
    lat: 37.582744,
    lng: 127.027356,
    name: "생명공학부 주점",
    day: 2,
    status: "Hot",
    location: "애기능농구코트",
    link: "https://www.instagram.com/ku_biotech_e/",
  },
  LifeSciences: {
    lat: 37.5826768,
    lng: 127.0275211,
    name: "생명과학부 주점",
    day: 2,
    status: "Hot",
    location: "애기능농구코트",
    link: "https://www.instagram.com/kudl_actin/",
  },
  CuryStudy: {
    lat: 37.5826497,
    lng: 127.0274292,
    name: "고려대 커리손으로 먹기 연구회",
    day: 2,
    status: "Hot",
    location: "애기능농구코트",
  },
  // Spago: {
  //   lat: 37.5826497,
  //   lng: 127.0274292,
  //   name: "Spago 주점",
  //   day: 2,
  //   status: "Hot",
  //   location: "국제관",
  // },
};
// Biz: { lat: 37.5902808, lng: 127.0348337, name: "경영대 주점" },
export const bar_pins_day3: Record<string, Pin> = {
  //민주광장

  KoreanPaintingClub: {
    lat: 37.587351,
    lng: 127.031275,
    name: "한국화회 주점",
    day: 3,
    status: "Hot",
    location: "민광",
    link: "https://www.instagram.com/ku_kpaint/",
  },

  History: {
    lat: 37.587409,
    lng: 127.031395,
    name: "사학과 주점",
    day: 3,
    status: "Hot",
    location: "민광",
    link: "https://www.instagram.com/ku._.historia/",
  },

  KUFit: {
    lat: 37.587179,
    lng: 127.031511,
    name: "쿠핏 주점",
    day: 3,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/p/C7BwzwxvLaP/?img_index=1",
  },

  Misdirection: {
    lat: 37.587541,
    lng: 127.031559,
    name: "미스디렉션 주점",
    day: 3,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ku_magic/",
  },

  ArchitectureAndEnvironmentalEngineering: {
    lat: 37.587619,
    lng: 127.031672,
    name: "건축사회환경공학부 주점",
    day: 3,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/ace__ku/",
  },

  SkiClub: {
    lat: 37.587396,
    lng: 127.031752,
    name: "스키부 주점",
    day: 3,
    status: "Hot",
    location: "민광",
    link: "https://www.instagram.com/kuskiteam/",
  },

  Nursing: {
    lat: 37.587545,
    lng: 127.031785,
    name: "간호학과 주점",
    day: 3,
    status: "icon",
    location: "민광",
    link: "https://www.instagram.com/erum_kucon/",
  },

  //농구코트

  SommelierClub: {
    // lat: 37.5873186,
    // lng: 127.0329112,
    lat: 37.5873784,
    lng: 127.033214,
    name: "소믈리에 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/ku_sommelier/",
  },
  SunshineVillege: {
    // lat: 37.5873784,
    // lng: 127.0329835,
    lat: 37.5872687,
    lng: 127.033214,
    name: "햇빛촌 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/kusunshinevill/",
  },
  KoreanEducation: {
    // lat: 37.5872396,
    // lng: 127.0329665,
    lat: 37.5873186,
    lng: 127.0331565,
    name: "국어교육과 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/kukle_37/",
  },

  EqoosClub: {
    // lat: 37.5871633,
    // lng: 127.0331123,
    lat: 37.587396,
    lng: 127.0331123,
    name: "이쿠스 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/__iccus_tagram/",
  },

  HUBand: {
    // lat: 37.5872066,
    // lng: 127.0331696,
    lat: 37.587474,
    lng: 127.0331565,
    name: "자유전공학부 밴드부 HU 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/harmonia_uranica/",
  },

  JudoClub: {
    // lat: 37.5872687,
    // lng: 127.033214,
    lat: 37.5873784,
    lng: 127.0329835,
    name: "유도부 주점",
    day: 3,
    status: "Cool",
    location: "농코",
    link: "https://www.instagram.com/ku_judoteam/",
  },

  HealthAndEnvironmentalScience: {
    // lat: 37.587474,
    // lng: 127.0331565,
    lat: 37.5872066,
    lng: 127.0331696,
    name: "보건환경융합과학부 주점",
    day: 3,
    status: "icon",
    location: "농코",
    link: "https://www.instagram.com/ku_hes_2024/",
  },

  //원형극장

  Sociology: {
    lat: 37.5866478,
    lng: 127.0325138,
    name: "사회학과 주점",
    day: 3,
    status: "icon",
    location: "원형",
    link: "https://www.instagram.com/p/C7DmxMEvebf/?img_index=3",
  },

  //국제관 앞
  JASS: {
    lat: 37.5877967,
    lng: 127.0308156,
    name: "JASS 주점",
    day: 3,
    status: "Hot",
    location: "국제관",
    link: "https://www.instagram.com/ku_jass/",
  },

  Spanish: {
    lat: 37.5879537,
    lng: 127.0309926,
    name: "서어서문학과 주점",
    day: 3,
    status: "icon",
    location: "국제관",
    link: "https://www.instagram.com/ku_spanish/",
  },

  //문과대 서관 앞
  Russian: {
    lat: 37.5883255,
    lng: 127.0317257,
    name: "노어노문학과 주점",
    day: 3,
    status: "icon",
    location: "서관",
    link: "https://www.instagram.com/kuruss_1974/",
  },
  TaekwondoClub: {
    lat: 37.5885547,
    lng: 127.0319964,
    name: "태권도부 주점",
    day: 3,
    status: "icon",
    location: "서관",
    link: "https://www.instagram.com/ku_tkd/",
  },
  //애기능 농구코트
  EarthAndEnvironmentalSciences: {
    lat: 37.582744,
    lng: 127.027356,
    name: "지구환경과학과 주점",
    day: 3,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/kuees_ideal24/",
  },
  MechanicalEngineering: {
    lat: 37.5827788,
    lng: 127.0274879,
    name: "기계공학부 주점",
    link: "https://www.instagram.com/ku_mechanical/",
    day: 3,
    status: "icon",
    location: "애농코",
  },
  Architecture: {
    lat: 37.5826497,
    lng: 127.0274292,
    name: "건축학과 주점",
    day: 3,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/korea_univ_archi/",
  },

  AmartureSoccer: {
    lat: 37.5826768,
    lng: 127.0275211,
    name: "아마추어 축구부",
    day: 3,
    status: "icon",
    location: "애농코",
    link: "https://www.instagram.com/amateur_football.ku/",
  },

  // PoliticalScienceAndDiplomacy: {
  //   lat: 37.5826768,
  //   lng: 127.0275211,
  //   name: "정치외교학과 주점",
  //   day: 3,
  //   status: "Hot",
  //   location: "애농코",
  //   link: "https://www.instagram.com/ku_ps1r/",
  // },
  // PublicAdministration: {
  //   lat: 37.5827098,
  //   lng: 127.0275801,
  //   name: "행정학과 주점",
  //   day: 3,
  //   status: "Hot",
  //   location: "애농코",
  //   link: "https://www.instagram.com/kupa.korea/",
  // },

  //하나스퀘어
  Mathematics: {
    lat: 37.5850251,
    lng: 127.0262724,
    name: "수학과 주점",
    day: 3,
    status: "icon",
    location: "하스",
    link: "https://www.instagram.com/ku.math/",
  },
};

export const bar_pins: Record<string, Pin> = {
  // //국제관 앞
  // ICCUS: {
  //   lat: 37.5878874,
  //   lng: 127.0308425,
  //   name: "ICCUS",
  //   status: "Hot",
  // },
  // JASS: { lat: 37.5880021, lng: 127.0309263, name: "JASS", status: "Hot" },
  // //민주광장
  // // GeographyEducation: {
  // //   lat: 37.5874291,
  // //   lng: 127.0313045,
  // //   name: "지리교육과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CsklU1yv0CO/",
  // // },
  // Germany: {
  //   lat: 37.5874291,
  //   lng: 127.0313045,
  //   name: "독어독문학과",
  //   status: "Hot",
  // },
  // WhaGu: {
  //   lat: 37.58761,
  //   lng: 127.031519,
  //   name: "화구회",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsgdHrbPiRB/",
  // },
  // // ChemicalBiologicalSciences: {
  // //   lat: 37.5873037,
  // //   lng: 127.031319,
  // //   name: "화공생명공학과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/Csa6jvrp5u9/",
  // // },
  // PenCing: {
  //   lat: 37.5873037,
  //   lng: 127.031319,
  //   name: "펜싱부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsalrBzJdYC/",
  // },
  // // Society: {
  // //   lat: 37.5876026,
  // //   lng: 127.0317153,
  // //   name: "사회학과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CsVaFSvvcWY/",
  // // },
  // Society: {
  //   lat: 37.5876026,
  //   lng: 127.0317153,
  //   name: "고대문학회",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsoWKM0h8pS/",
  // },
  // // Mountain: {
  // //   lat: 37.5874894,
  // //   lng: 127.0315858,
  // //   name: "산악부",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CslahDPSyf3/",
  // // },
  // //민주광장2
  // // KoreaEducation: {
  // //   lat: 37.5873411,
  // //   lng: 127.0315587,
  // //   name: "국어교육과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/Csf_ZssJk6t/",
  // // },
  // SoccerAmatuer: {
  //   lat: 37.5873411,
  //   lng: 127.0315587,
  //   name: "아마추어축구부",
  //   status: "Hot",
  // },
  // // Language: {
  // //   lat: 37.5871998,
  // //   lng: 127.0315671,
  // //   name: "언어학과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CsgidThPCoQ/",
  // // },
  // DolBit: {
  //   lat: 37.5871998,
  //   lng: 127.0315671,
  //   name: "돌빛",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csd3t2FLE-f/",
  // },
  // // IndustrialManagementEngineering: {
  // //   lat: 37.5873951,
  // //   lng: 127.0318366,
  // //   name: "산업경영공학부",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CshxVvDhG05/",
  // // },
  // ArchitecterSocietyEngineering: {
  //   lat: 37.5873951,
  //   lng: 127.0318366,
  //   name: "건축사회환경공학부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CskfgWmpWot/",
  // },
  // // Miss: {
  // //   lat: 37.5874601,
  // //   lng: 127.0319117,
  // //   name: "미스디렉션",
  // //   status: "Hot",
  // // },
  // // Philosophy: {
  // //   lat: 37.587284,
  // //   lng: 127.031741,
  // //   name: "철학과",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CslSjF7PT7Z/",
  // // },
  // //학생회관 뒤 원형광장
  // // FestivalPreparatoryCommittee: {
  // //   lat: 37.586837,
  // //   lng: 127.032606,
  // //   name: "석탑대동제준비위원회",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CslSjF7PT7Z/",
  // // },
  // //농구코트
  // Suho: {
  //   lat: 37.587194,
  //   lng: 127.033129,
  //   name: "수호회",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csgn_nerEUh/",
  // },
  // weightLifting: {
  //   lat: 37.587244,
  //   lng: 127.033235,
  //   name: "역도부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsdaJrOPSQ0/",
  // },
  // HatBitChron: {
  //   lat: 37.587301,
  //   lng: 127.033099,
  //   name: "산악부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CslahDPSyf3/",
  // },
  // Judo: {
  //   lat: 37.587418,
  //   lng: 127.033023,
  //   name: "소믈리에",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CslyqklPeIm/",
  // },
  // DSM: {
  //   lat: 37.587343,
  //   lng: 127.03292,
  //   name: "쿠핏",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csnly5eP-QC/",
  // },
  // //이과캠 농구코트
  // // EngineeringExecutiveCommittee: {
  // //   lat: 37.582669,
  // //   lng: 127.027476,
  // //   name: "공과대학집행위원회",
  // //   status: "Hot",
  // //   link: "https://www.instagram.com/p/CsiDN0npVCg/",
  // // },
  // Architecture: {
  //   lat: 37.582723,
  //   lng: 127.027461,
  //   name: "FREAKS",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CskjvACPqoq/",
  // },
  // Engineering: {
  //   lat: 37.582744,
  //   lng: 127.027356,
  //   name: "기계공학부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csgd6eVpCA-/",
  // },
};

const basketBall = {
  // //농구코트
  // Suho: {
  //   lat: 37.587194,
  //   lng: 127.033129,
  //   name: "수호회",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csgn_nerEUh/",
  // },
  // weightLifting: {
  //   lat: 37.587244,
  //   lng: 127.033235,
  //   name: "역도부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsdaJrOPSQ0/",
  // },
  // HatBitChron: {
  //   lat: 37.587301,
  //   lng: 127.033099,
  //   name: "햇빛촌",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Cslle-shK0w/",
  // },
  // Judo: {
  //   lat: 37.587418,
  //   lng: 127.033023,
  //   name: "유도부",
  //   status: "Hot",
  // },
  // DSM: {
  //   lat: 37.587343,
  //   lng: 127.03292,
  //   name: "DSM",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsiV1LPLUD2/",
  // },
  // //이과캠 농구코트
  // EngineeringExecutiveCommittee: {
  //   lat: 37.582669,
  //   lng: 127.027476,
  //   name: "공과대학집행위원회",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsiDN0npVCg/",
  // },
  // Architecture: {
  //   lat: 37.582723,
  //   lng: 127.027461,
  //   name: "건축학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsjW3nPvX8V/",
  // },
  // Engineering: {
  //   lat: 37.582744,
  //   lng: 127.027356,
  //   name: "기계공학부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csgd6eVpCA-/",
  // },
  //engineering
  // 공과대학집행위원회,
};

const minju = {
  // 국제관앞
  // Energy: {
  //   lat: 37.5877625,
  //   lng: 127.0307064,
  //   name: "서어서문학과",
  //   status: "Hot",
  // },
  // Chemibio: { lat: 37.588105, lng: 127.0310179, name: "화공생명공학과" },
  //인문사회관 앞
  // Politics: { lat: 37.5874349, lng: 127.0312140, name: "정치외교학과" },
  // Interglobal: {
  //   lat: 37.5876055,
  //   lng: 127.03138,
  //   name: "국제학부 X 글로벌한국융합학부",
  //   status: "Hot",
  // },
  // // Sportsku: { lat: 37.5877420, lng: 127.0315127, name: "SPORTS KU" },
  // // 구름다리
  // Isback19: {
  //   lat: 37.5874099,
  //   lng: 127.0313149,
  //   name: "19학번ISBACK",
  //   status: "Hot",
  // },
  // // China: { lat: 37.5876302, lng: 127.0315496, name: "중어중문학과" },
  // //민주광장
  // Spanish: {
  //   lat: 37.5878874,
  //   lng: 127.0308425,
  //   name: "서어서문학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsVCpd8P0rP/",
  // },
  // Kuba: { lat: 37.5880021, lng: 127.0309263, name: "KUBA", status: "Hot" },
  // GeographyEducation: {
  //   lat: 37.5874291,
  //   lng: 127.0313045,
  //   name: "지리교육과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsklU1yv0CO/",
  // },
  // DepartmentOfPublicAdministration: {
  //   lat: 37.5875321,
  //   lng: 127.0316559,
  //   name: "행정학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsdYnaYv17K/",
  // },
  // ChemicalBiologicalSciences: {
  //   lat: 37.5873037,
  //   lng: 127.031319,
  //   name: "화공생명공학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csa6jvrp5u9/",
  // },
  // Mountain: {
  //   lat: 37.5876026,
  //   lng: 127.0317153,
  //   name: "산악부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CslahDPSyf3/",
  // },
  // //민주광장2
  // KoreaEducation: {
  //   lat: 37.5873411,
  //   lng: 127.0315587,
  //   name: "국어교육과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/Csf_ZssJk6t/",
  // },
  // Society: {
  //   lat: 37.5874894,
  //   lng: 127.0315858,
  //   name: "사회학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsVaFSvvcWY/",
  // },
  // Language: {
  //   lat: 37.5871998,
  //   lng: 127.0315671,
  //   name: "언어학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CsgidThPCoQ/",
  // },
  // IndustrialManagementEngineering: {
  //   lat: 37.5873951,
  //   lng: 127.0318366,
  //   name: "산업경영공학부",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CshxVvDhG05/",
  // },
  // Miss: {
  //   lat: 37.5874601,
  //   lng: 127.0319117,
  //   name: "미스디렉션",
  //   status: "Hot",
  // },
  // Philosophy: {
  //   lat: 37.5874166,
  //   lng: 127.0311996,
  //   name: "철학과",
  //   status: "Hot",
  //   link: "https://www.instagram.com/p/CslSjF7PT7Z/",
  // },
};
