import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { PathUrl } from "../types/router/pathUrl";
import { Joojum } from "../pages/Joojum/Joojum";
import { SimpleBottomNavigation } from "../components/bottomNavigation/BottomNavigation";
import Timeline from "../components/timeline/Timeline";

export const AuthRouter = () => {
  return (
    <>
      <BrowserRouter>
        <>
          <Routes>
            <Route path={`${PathUrl.Login}`} element={<Login />} />
            <Route path={`${PathUrl.Joojum}`} element={<Joojum />} />
            <Route path={`${PathUrl.Home}`} element={<Joojum />} />
            <Route path={`${PathUrl.Timeline}`} element={<Timeline />} />
            <Route path="*" element={<Navigate to={`${PathUrl.Joojum}`} />} />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
};
