import InstagramIcon from '@mui/icons-material/Instagram';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useContext } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { NoticeData, Pin } from "../../constant/pins";
import MyContext from "../../contexts/MyContext";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minWidth: 275,
  marginBottom: theme.spacing(2),
}));

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  height: 140,
  marginBottom: theme.spacing(2),
}));

const StyledSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f0f0f0",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0),
}));
const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const NameTypography = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const LocationTypography = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  marginBottom: theme.spacing(1),
}));

const DayTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

// Define the interface for the props
export interface JoojumCardProps {
  keyString: string;
  item: Pin;
  day: number;
  location: string;
  name: string;
  link: string;
  handleJoojumStatus: (key:string, pin:Pin) => void;
}

// Define the component
export const JoojumCard: React.FC<JoojumCardProps> = ({
  keyString,
  item,
  day,
  location,
  name,
  link,
  handleJoojumStatus,
}) => {
  const { setBottomSheetStatus, pinStatus } = useContext(MyContext);
  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <StyledCard onClick={() => handleJoojumStatus(keyString, item)}>
      <StyledCardContent>
        <NameTypography>{name}</NameTypography>
        <LocationTypography variant="h5" as="div">
          위치: {location}, {day}일차 주점
        </LocationTypography>
        {link && (
          <IconButton onClick={handleIconClick} aria-label="Instagram link">
            <InstagramIcon />
          </IconButton>
        )}
      </StyledCardContent>
      {NoticeData[keyString] && NoticeData[keyString]?.imageNumber >= 1 && (
        <StyledSwiper spaceBetween={10} slidesPerView={1}>
          {Array.from(
            { length: NoticeData[keyString]?.imageNumber },
            (_, index) => (
              <SwiperSlide
                key={index}
                style={{
                  backgroundColor: "#F5f5f5",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/joojum/image/${keyString}/Page${index + 1}.jpeg`}
                  alt={`Image ${index + 1}`}
                  style={{ width: "unset", height: "100%" }}
                />
              </SwiperSlide>
            )
          )}
        </StyledSwiper>
      )}
    </StyledCard>
  );
};
