import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Pin,
  bar_pins_day1,
  bar_pins_day2,
  bar_pins_day3,
} from "../../constant/pins";

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 10vh;
  margin: auto;
  padding: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 5;
`;
const InputBox = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 70%;
  padding: 8px 10px;

  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

/*const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;*/

const ResultsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ResultItem = styled.li`
  background-color: white;
  padding: 10px;
  margin-top: 2px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
interface SearchComponentProps {
  setJoojumState: React.Dispatch<
    React.SetStateAction<{ [key: string]: Pin } | null>
  >;
  clickCloseSearhComponent: () => void;
  handleJoojumStatus: (key: string, pin: Pin) => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  setJoojumState,
  clickCloseSearhComponent,
  handleJoojumStatus,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState<
    { [x: string]: Pin }[]
  >([]);

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
      handleSearchFast("");
    }
  }, []);

  const handleSearch = () => {
    const allJoojum = {
      // ...pins,
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };
    const filtered = Object.entries(allJoojum)
      .filter(([key, value]) => value.name.includes(searchTerm))
      .sort((a, b) => {
        // "Cool"인 상태를 우선적으로 정렬
        if (a[1].status === "icon" && b[1].status !== "icon") return -1;
        if (a[1].status !== "icon" && b[1].status === "icon") return 1;
        // 그 외의 경우 이름 기준으로 정렬
        return a[1].name.localeCompare(b[1].name);
      })
      .map(([key, value]) => ({ [key]: value }));
    setFilteredResults(filtered);
  };

  // 검색을 수행할 함수
  const handleSearchFast = (searchValue: string) => {
    console.log("Searching for:", searchValue);
    const allJoojum = {
      // ...pins,
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };
    const filtered = Object.entries(allJoojum)
      .filter(([key, value]) => value.name.includes(searchValue))
      .sort((a, b) => {
        // "Cool"인 상태를 우선적으로 정렬
        if (a[1].status === "icon" && b[1].status !== "icon") return -1;
        if (a[1].status !== "icon" && b[1].status === "icon") return 1;
        // 그 외의 경우 이름 기준으로 정렬
        return a[1].name.localeCompare(b[1].name);
      })
      .map(([key, value]) => ({ [key]: value }));
    setFilteredResults(filtered);
    // 검색 로직을 여기에 구현하세요. 예를 들어 API 호출 등
  };

  // 검색을 수행할 함수
  const handleSearchDirect = useCallback((searchValue: string) => {
    console.log("Searching for:", searchValue);
    const allJoojum = {
      // ...pins,
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };
    const filtered = Object.entries(allJoojum)
      .filter(([key, value]) => value.name.includes(searchValue))
      .sort((a, b) => {
        // "Cool"인 상태를 우선적으로 정렬
        if (a[1].status === "icon" && b[1].status !== "icon") return -1;
        if (a[1].status !== "icon" && b[1].status === "icon") return 1;
        // 그 외의 경우 이름 기준으로 정렬
        return a[1].name.localeCompare(b[1].name);
      })
      .map(([key, value]) => ({ [key]: value }));
    setFilteredResults(filtered);
    // 검색 로직을 여기에 구현하세요. 예를 들어 API 호출 등
  }, []);

  // 입력값이 변경될 때 호출될 함수, lodash의 debounce 함수를 사용하여 1000ms 후에 실행
  const debouncedSearch = useCallback(
    debounce((nextValue: string) => handleSearchDirect(nextValue), 500),
    [handleSearch]
  );

  const clickSearchResult = (item: { [x: string]: Pin }) => {
    // setPinStatus(item[0]);
    handleJoojumStatus(Object.keys(item)[0], Object.values(item)[0]);
    // setJoojumState(item);
  };

  return (
    <Container>
      <InputBox>
        <Paper
          component="div"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            sx={{ p: "10px" }}
            aria-label="close"
            onClick={clickCloseSearhComponent}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <InputBase
            // value={searchTerm}
            inputRef={searchInputRef}
            sx={{ ml: 1, flex: 1 }}
            placeholder="관심 주점을 검색해봐!"
            inputProps={{ "aria-label": "관심 주점을 검색해봐!" }}
            onChange={(e) => debouncedSearch(e.target.value)}
            onKeyPress={(event: any) =>
              event.key === "Enter" && handleSearchFast(event.target.value)
            }
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          {/* <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
          >
            <DirectionsIcon />
          </IconButton> */}
        </Paper>

        {/* <Input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(event) => event.key === "Enter" && handleSearch()}
        />
        <Button
          variant="contained"
          endIcon={<SearchIcon />}
          onClick={handleSearch}
        >
          Search
        </Button> */}
      </InputBox>
      {/* <ResultsList>
        {
        
        filteredResults.map((filteredResult)=> Object.entries(filteredResult).map((item) => (
             <ResultItem key={item[0]} onClick={()=> clickSearchResult({[item[0]]:item[1]})}>
                {item[1].name} (Day: {item[1].day}일차) {item[0]}
            </ResultItem>
        )))
        
        }
        

      </ResultsList> 
      
      <Avatar
                  alt={`Avatar n°${value[1].name}`}
                  src={`/static/images/avatar/${value[1].name}.jpg`}
                />*/}
      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {filteredResults.map((filteredResult) =>
          Object.entries(filteredResult).map((value) => {
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <ListItem
                sx={{ paddingLeft: "20px", paddingRight: "20px" }}
                onClick={() => {
                  handleJoojumStatus(value[0], value[1]);
                  handleSearchFast(value[1].name);
                }}
                key={value[0]}
                disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar>
                    {value[1].status === "icon" ? (
                      <Avatar>
                        <img
                          style={{ width: "unset", height: "100%" }}
                          src={`${process.env.PUBLIC_URL}/joojum/image/${value[0]}/icon.jpeg`}
                        />
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: "red" }} // 빨간 배경 적용 아래 name 말고 location(민광, 애농코, 국제관, 서관, 하스, 원형, 농코)이 보일 수 있도록...글자길이 3자일때는 작게
                      >
                        {value[1].location}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={`${value[1].name} (Day: ${value[1].day}일차)`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })
        )}
      </List>
    </Container>
  );
};

export default SearchComponent;
