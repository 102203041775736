import { collection, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { SimpleBottomNavigation } from "../../components/bottomNavigation/BottomNavigation";
import BottomSheet from "../../components/bottomSheet/BottomSheet";
import { JoojumCard } from "../../components/joojumcard/JoojumCard";
import { Notice } from "../../components/notice/Notice";
import { SearchButton } from "../../components/searchButton/SearchButton";
import SearchComponent from "../../components/searchComponent/SearchComponent";
import {
  Pin,
  bar_pins_day1,
  bar_pins_day2,
  bar_pins_day3,
} from "../../constant/pins";
import MyContext from "../../contexts/MyContext";
import { db } from "../../firebase";
import { urlByStatusWithPinStatus } from "../../utils/status/status";
import * as S from "./JoojumStyles";

export const Joojum = () => {
  const { bottomSheetStatus, setBottomSheetStatus, pinStatus, setPinStatus } =
    useContext(MyContext);
  const mapRef = useRef<HTMLElement | null | any>(null);
  const [eventDocuments, setEventDocuments] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [joojumState, setJoojumState] = useState<{ [x: string]: Pin } | null>(
    null
  );

  const [focusJoojum, setFocusJoojum] = useState<{ [x: string]: Pin } | null>(
    null
  );

  // useState를 통해 현재 포커스된 마커와 인포윈도우 상태를 관리합니다.
  const [currentMarker, setCurrentMarker] = useState<naver.maps.Marker[]>([]);
  const [currentCustomOverlay, setCurrentCustomOverlay] = useState<
    CustomOverlay[]
  >([]);

  const [currentPolygonList, setCurrentPolygonList] = useState<
    naver.maps.Polygon[]
  >([]);

  const [currentInfoWindow, setCurrentInfoWindow] =
    useState<naver.maps.InfoWindow | null>(null);

  const [likeSort, setLikeSort] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState<Boolean>(false);
  const [dayStatus, setDayStatus] = useState(3);
  const [bottomSheetHeight, setBottomSheetHeight] = useState<number>(300);

  const handleClickLike = () => {
    setLikeSort(true);
  };

  const normalizeKeys = (obj: {
    [key: string]: Pin;
  }): { [key: string]: Pin } => {
    const normalizedObj: { [key: string]: Pin } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        normalizedObj[key.toLowerCase()] = obj[key];
      }
    }
    return normalizedObj;
  };

  const keyObjInkey = (obj: { [key: string]: Pin }) => {
    const normalizedObj: { [key: string]: string } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        normalizedObj[key.toLowerCase()] = key;
      }
    }
    return normalizedObj;
  };

  const handleClickRecent = () => {
    setLikeSort(false);
  };

  useEffect(() => {
    const allJoojum: { [key: string]: Pin } = {
      // ...pins,
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };
    const queryParams = new URLSearchParams(window.location.search);
    const department = queryParams.get("department") || "";
    // console.log(department);

    // console.log(allJoojum);
    // console.log(allJoojum[department]);
    let defaultJoojum = {};
    if (dayStatus == 1) {
      defaultJoojum = bar_pins_day1;
    } else if (dayStatus == 2) {
      defaultJoojum = bar_pins_day2;
    } else if (dayStatus == 3) {
      defaultJoojum = bar_pins_day3;
    }

    const getAllJoojum = normalizeKeys(allJoojum);
    const getAllKeyBylowerCase = keyObjInkey(allJoojum);
    const departmentKey = getAllKeyBylowerCase[department.toLowerCase()];

    const result =
      departmentKey && allJoojum[departmentKey]
        ? { [departmentKey]: allJoojum[departmentKey] }
        : { ...defaultJoojum };

    setPinStatus(departmentKey);
    setJoojumState({ ...result });
    // setFocusJoojum()
    // const getAllJoojum = normalizeKeys(allJoojum);
    // const getAllKeyBylowerCase = keyObjInkey(allJoojum);
    // // console.log("----department---");
    // // console.log(department, getAllJoojum[department.toLocaleLowerCase()]);
    // console.log("allJoojum", getAllKeyBylowerCase[department.toLowerCase()]);

    // if (department && getAllJoojum[department.toLocaleLowerCase()]) {
    //   const pinKeyByLowerCase =
    //     getAllKeyBylowerCase[department.toLocaleLowerCase()];

    //   console.log("department");
    //   handleJoojumStatus(pinKeyByLowerCase, allJoojum[pinKeyByLowerCase]);
    // }
  }, []);

  useEffect(() => {
    // lat: 37.587067, lng: 127.032055,

    // const overlay = new naver.maps.GroundOverlay(
    //     `${process.env.PUBLIC_URL}/Empty-img.png`,
    //   );overlay.setMap(map);
    // lat: 37.587067, lng: 127.032055,
    // allJoojum[department].lat, allJoojum[department].lng

    // 사용자 정의 오버레이 클래스 정의
    const markerList: naver.maps.Marker[] = [];
    const customOverlayList: CustomOverlay[] = [];
    // const windowArray: naver.maps.Marker[] = [];
    if (currentMarker) {
      currentMarker.forEach((item) => item.setMap(null));
    }

    if (currentPolygonList) {
      currentPolygonList.forEach((item) => item.setMap(null));
    }

    if (currentCustomOverlay) {
      currentCustomOverlay.forEach((item) => item.setMap(null));
    }

    if (currentInfoWindow) {
      currentInfoWindow.close();
    }

    const infoWindow12 = new window.naver.maps.InfoWindow({
      content: `,
                    <div style="width:150px;text-align:center;padding:10px;">
                    </div>`,
    });
    // InfoWindow 열기
    infoWindow12.close();

    if (joojumState) {
      clickCloseSearhComponent();

      setBottomSheetStatus(true);
      decideBottomSheetHeight();
      const firstJoomjum = Object.values(joojumState).map((item) => item);

      if (!mapRef.current) {
        mapRef.current = new naver.maps.Map("map", {
          //지도 추가, 좌표를 기점으로 주변 지도가 추가된다.
          center: new naver.maps.LatLng(
            firstJoomjum[0].lat,
            firstJoomjum[0].lng
          ),
          zoom: 18,
        });
      } else {
        mapRef.current.setCenter(
          new naver.maps.LatLng(firstJoomjum[0].lat, firstJoomjum[0].lng)
        );
        mapRef.current.setZoom(18);
      }

      const polygonList: naver.maps.Polygon[] = [];

      var polygon = new naver.maps.Polygon({
        map: mapRef.current,
        paths: [
          [
            //   new naver.maps.LatLng(37.587037, 127.0316157),
            //   new naver.maps.LatLng(37.5868908, 127.0318258),
            //   new naver.maps.LatLng(37.5872712,127.0322503),
            //    new naver.maps.LatLng(37.5872871,127.0322003),
            //   new naver.maps.LatLng(37.5872554, 127.0318147),
            new naver.maps.LatLng(37.5870431, 127.0316068),
            new naver.maps.LatLng(37.5868908, 127.0318258),
            new naver.maps.LatLng(37.5872712, 127.0322503),
            new naver.maps.LatLng(37.5874284, 127.0320417),
            //new naver.maps.LatLng(37.5872867, 127.0318748),
          ],
        ],
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 0.6,
        strokeWeight: 3,
      });

      polygonList.push(polygon);
      var polygon_food1 = new naver.maps.Polygon({
        map: mapRef.current,
        paths: [
          [
            //   new naver.maps.LatLng(37.587037, 127.0316157),
            //   new naver.maps.LatLng(37.5868908, 127.0318258),
            //   new naver.maps.LatLng(37.5872712,127.0322503),
            //    new naver.maps.LatLng(37.5872871,127.0322003),
            //   new naver.maps.LatLng(37.5872554, 127.0318147),
            new naver.maps.LatLng(37.5874099, 127.0311189),
            new naver.maps.LatLng(37.5877895, 127.0315438),
            new naver.maps.LatLng(37.5877629, 127.0315773),
            new naver.maps.LatLng(37.5873854, 127.0311575),
          ],
        ],
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 0.6,
        strokeWeight: 3,
      });

      polygonList.push(polygon_food1);
      var polygon_food2 = new naver.maps.Polygon({
        map: mapRef.current,
        paths: [
          [
            //   new naver.maps.LatLng(37.587037, 127.0316157),
            //   new naver.maps.LatLng(37.5868908, 127.0318258),
            //   new naver.maps.LatLng(37.5872712,127.0322503),
            //    new naver.maps.LatLng(37.5872871,127.0322003),
            //   new naver.maps.LatLng(37.5872554, 127.0318147),
            new naver.maps.LatLng(37.5835893, 127.0282903),
            new naver.maps.LatLng(37.5835346, 127.0281592),
            new naver.maps.LatLng(37.5834283, 127.0281579),
            new naver.maps.LatLng(37.5834272, 127.0282863),

            //new naver.maps.LatLng(37.5834809, 127.0284469),
          ],
        ],
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 0.6,
        strokeWeight: 3,
      });

      polygonList.push(polygon_food2);

      var polygon_screen = new naver.maps.Polygon({
        map: mapRef.current,
        paths: [
          [
            //   new naver.maps.LatLng(37.587037, 127.0316157),
            //   new naver.maps.LatLng(37.5868908, 127.0318258),
            //   new naver.maps.LatLng(37.5872712,127.0322503),
            //    new naver.maps.LatLng(37.5872871,127.0322003),
            //   new naver.maps.LatLng(37.5872554, 127.0318147),
            new naver.maps.LatLng(37.5835892, 127.0284196),
            new naver.maps.LatLng(37.5834584, 127.0284198),
            new naver.maps.LatLng(37.5834595, 127.0283722),
            new naver.maps.LatLng(37.5835887, 127.028376),
          ],
        ],
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 0.6,
        strokeWeight: 3,
      });

      polygonList.push(polygon_screen);

      setCurrentPolygonList(polygonList);

      // const overlay11 = new CustomOverlay({
      //   map: mapRef.current,
      //   position: new window.naver.maps.LatLng(37.5871451, 127.0318702),
      //   content: `
      //   <div style="font-size:20px;width:30px;height:40px;position:relative;">
      //   <div style="position:absolute;width:100%;top:30px;left:50%;transform: translateX(-50%);min-width:200px;">
      //  고대생존
      //   </div>
      //   </div>`,
      // });

      const thisMarkerList = [];
      const thisOverlayList = [];

      const overlayPolygon = new CustomOverlay({
        map: mapRef.current,
        position: new window.naver.maps.LatLng(37.587158375, 127.03193115),
        content: `
        <div style="font-size: 15px; width: 30px; height: 40px; position: relative;">
        <div style="position: absolute; width: 100%; top: 30px; left: 50%; transform: translateX(-50%); min-width: 200px; text-align: center; color: #FFFFFF; text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;">
        고대생존
        </div>
        </div>`,
      });

      const overlayPolygon_food1 = new CustomOverlay({
        map: mapRef.current,
        position: new window.naver.maps.LatLng(37.587586925, 127.031349375),
        content: `
        <div style="font-size: 15px; min-width: 200px; height: 40px; position: relative;">
        <div style="position: absolute; width: 100%; top: 30px; left: 50%; transform: translateX(-50%); min-width: 200px; text-align: center; color: #FFFFFF; text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;">
        푸드트럭
        </div>
        </div>`,
      });

      const overlayPolygon_food2 = new CustomOverlay({
        map: mapRef.current,
        position: new window.naver.maps.LatLng(37.58349485, 127.028223425),
        content: `
        <div style="font-size: 15px; min-width: 200px; height: 40px; position: relative;">
      <div style="position: absolute; width: 100%; top: 30px; left: 50%; transform: translateX(-50%); min-width: 200px; text-align: center; color: #FFFFFF; text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;">
        푸드트럭
        </div>
        </div>`,
      });

      const overlayPolygon_screen = new CustomOverlay({
        map: mapRef.current,
        position: new window.naver.maps.LatLng(37.58352395, 127.0283969),
        content: `
        <div style="font-size: 15px; min-width: 200px; height: 40px; position: relative;">
        <div style="position: absolute; width: 100%; top: 30px; left: 50%; transform: translateX(-50%); min-width: 200px; text-align: center; color: #FFFFFF; text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;">
        공연스크린
        </div>
        </div>`,
      });

      const polygonOverlayList = [
        overlayPolygon,
        overlayPolygon_food1,
        overlayPolygon_food2,
        overlayPolygon_screen,
      ];

      // naver.maps.Event.addListener(
      //   mapRef.current,
      //   "zoom_changed",
      //   function (zoom: number) {
      //     // alert("zoom 값이 " + zoom + "으로 변경되었습니다!");
      //     if (zoom < 18) {
      //       // overlay.setMap(null);
      //       // polygonList.forEach((item) => item.setMap(null));
      //       polygonOverlayList.forEach((item) => item.setMap(null));
      //     } else {
      //       // overlay.setMap(mapRef.current);
      //       // polygonList.forEach((item) => item.setMap(mapRef.current));
      //       polygonOverlayList.forEach((item) => item.setMap(mapRef.current));
      //     }
      //   }
      // );

      // polygon: 37.587158375, 127.03193115;
      // polygon_food1: 37.587586925, 127.031349375;
      // polygon_food2: 37.58349485, 127.028223425;
      // polygon_screen: 37.58352395, 127.0283969;

      Object.entries(joojumState).forEach((item) => {
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(item[1].lat, item[1].lng),
          map: mapRef.current,
          icon: {
            url: urlByStatusWithPinStatus(item[1].status, item[0]),
            size: new naver.maps.Size(30, 40),
            scaledSize: new naver.maps.Size(30, 40),
            origin: new naver.maps.Point(0, 0),
            anchor: new naver.maps.Point(2, 13),
          },
        });

        thisMarkerList.push(marker);

        const overlay = new CustomOverlay({
          map: mapRef.current,
          position: new window.naver.maps.LatLng(
            item[1].lat,
            // - 0.00002
            item[1].lng
            // - 0.00009
          ),
          content: `
        <div style="font-size: 10px; width: 30px; height: 40px; position: relative;">
        <div style="position: absolute; width: 100%; top: 30px; left: 50%; transform: translateX(-50%); min-width: 200px; text-align: center; color: black; text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;">
        ${item[1].name}
        </div>
        </div>`,
        });

        markerList.push(marker);
        customOverlayList.push(overlay);

        if (firstJoomjum.length == 1) {
          setDayStatus(item[1].day ? item[1].day : dayStatus);
          setPinStatus(item[0]);
          setFocusJoojum({ [item[0]]: item[1] });

          const infoWindow = new window.naver.maps.InfoWindow({
            content: `,
                    <div style="width:150px;text-align:center;padding:10px;">
                        <div>${item[1].name}</div>
                        ${
                          item[1].link
                            ? `<a href=${item[1].link} target="_blank">👉 주점인스타 바로가기</a>`
                            : ""
                        }
                    </div>`,
          });
          // InfoWindow 열기
          infoWindow.open(mapRef.current, marker);

          setCurrentInfoWindow(infoWindow);

          const baseZoom = 18;
          const currentZoom = mapRef.current.getZoom();

          // 줌 레벨에 따른 스케일 인자 계산
          const scale = Math.pow(2, baseZoom - currentZoom);

          const baseLatChange = 0.000825;
          const baseLngChange = 0.00000425;

          // 줌 레벨에 따라 변경값 조정
          const scaledLatChange = baseLatChange * scale;
          const scaledLngChange = baseLngChange * scale;
          mapRef.current.panTo(
            new naver.maps.LatLng(
              item[1].lat - scaledLatChange,
              item[1].lng - scaledLngChange
            )
          );
        } else {
          setFocusJoojum(null);
        }

        overlay.setMap(mapRef.current);

        naver.maps.Event.addListener(
          mapRef.current,
          "zoom_changed",
          function (zoom: number) {
            // alert("zoom 값이 " + zoom + "으로 변경되었습니다!");
            if (zoom < 18) {
              overlay.setMap(null);
              polygonList.forEach((item) => item.setMap(null));
              polygonOverlayList.forEach((item) => item.setMap(null));
            } else if (overlay.getMap() === null) {
              overlay.setMap(mapRef.current);
              polygonList.forEach((item) => item.setMap(mapRef.current));
              polygonOverlayList.forEach((item) => item.setMap(mapRef.current));
            }
          }
        );

        const infoWindowClick = new window.naver.maps.InfoWindow({
          content: `,
                    <div style="width:150px;text-align:center;padding:10px;">
                        <div>${item[1].name}</div>
                        ${
                          item[1].link
                            ? `<a href=${item[1].link} target="_blank">👉 주점인스타 바로가기</a>`
                            : ""
                        }
                    </div>`,
        });

        window.naver.maps.Event.addListener(marker, "click", (e) => {
          infoWindowClick.open(mapRef.current, marker);
          setCurrentInfoWindow(infoWindowClick);
          setDayStatus(item[1].day ? item[1].day : 0);
          // setJoojumState({ [item[0]]: item[1] });
          setFocusJoojum({ [item[0]]: item[1] });
          setBottomSheetStatus(true);
          setPinStatus(item[0]);
          const baseZoom = 18;
          const currentZoom = mapRef.current.getZoom();

          // 줌 레벨에 따른 스케일 인자 계산
          const scale = Math.pow(2, baseZoom - currentZoom);

          const baseLatChange = 0.000825;
          const baseLngChange = 0.00000425;

          // 줌 레벨에 따라 변경값 조정
          const scaledLatChange = baseLatChange * scale;
          const scaledLngChange = baseLngChange * scale;
          mapRef.current.panTo(
            new naver.maps.LatLng(
              item[1].lat - scaledLatChange,
              item[1].lng - scaledLngChange
            )
          );
        });
      });

      setCurrentMarker(markerList);
      setCurrentCustomOverlay(customOverlayList);
    } else {
      mapRef.current = new naver.maps.Map("map", {
        //지도 추가, 좌표를 기점으로 주변 지도가 추가된다.
        center: new naver.maps.LatLng(37.587067, 127.032055),
        zoom: 18,
      });
    }
    return () => {
      setBottomSheetStatus(false);

      // 컴포넌트가 언마운트되면 마커와 인포윈도우를 제거합니다.
      if (markerList.length > 0) {
        markerList.forEach((item) => item.setMap(null));
      }

      if (customOverlayList.length > 0) {
        customOverlayList.forEach((item) => item.setMap(null));
      }

      if (markerList.length > 0) {
        markerList.forEach((item) => item.setMap(null));
      }

      if (currentCustomOverlay.length > 0) {
        currentCustomOverlay.forEach((item) => item.setMap(null));
      }

      if (currentPolygonList.length > 0) {
        currentPolygonList.forEach((item) => item.setMap(null));
      }

      // if (currentMarker) {
      //   currentMarker.forEach((item) => item.setMap(null));
      // }

      // if (currentPolygonList) {
      //   currentPolygonList.forEach((item) => item.setMap(null));
      // }

      // if (currentCustomOverlay) {
      //   currentCustomOverlay.forEach((item) => item.setMap(null));
      // }

      // if (currentInfoWindow) {
      //   currentInfoWindow.close();
      // }
      // if (infoWindow) {
      //   infoWindow.close();
      // }
    };
  }, [joojumState]);

  useEffect(() => {
    // 컬렉션 참조 생성

    const collectionRef = collection(db, "Post");
    // 실시간 리스너 설정
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const newEventDocuments: any = [];
      querySnapshot.forEach((doc) => {
        if (doc.data()) {
          newEventDocuments.push({
            ...doc.data(),
            id: doc.id,
          });
        }
      });
      setEventDocuments(newEventDocuments);
      setEventCount(newEventDocuments.length);
    });

    // 컴포넌트가 언마운트되면 실시간 리스너 해제
    return () => {
      unsubscribe();
    };
  }, []); // 의존성 배열에 빈 배열 사용

  // useEffect(() => {
  //   if (eventDocuments.length > 0) {
  //     setEventCount(eventDocuments.length);
  //   }
  // }, [eventDocuments]);

  const clickAllJoojum = (e: any) => {
    setJoojumState(bar_pins_day2);
  };

  //버튼 이벤트 핸들러
  const clickDay1Joojum = () => {
    setJoojumState(bar_pins_day1);
    setPinStatus("");
    setDayStatus(1);
  };

  const clickDay2Joojum = () => {
    setJoojumState(bar_pins_day2);
    setPinStatus("");
    setDayStatus(2);
  };

  const clickDay3Joojum = () => {
    setJoojumState(bar_pins_day3);
    setPinStatus("");
    setDayStatus(3);
  };

  const filterPinsByLocationsAndDay = (locations: string[], day: number) => {
    const allJoojum: { [key: string]: Pin } = {
      // ...pins,
      ...bar_pins_day1,
      ...bar_pins_day2,
      ...bar_pins_day3,
    };
    const filteredJoojum = Object.keys(allJoojum)
      .filter(
        (key) =>
          allJoojum[key].location !== undefined &&
          locations.includes(allJoojum[key].location!) &&
          allJoojum[key].day === day
      )
      .reduce((obj: { [key: string]: Pin }, key) => {
        obj[key] = allJoojum[key];
        return obj;
      }, {});
    return filteredJoojum;
  };

  const clickEcampus = () => {
    // setJoojumState(filterPinsByLocationsAndDay(["애농코", "하스"], dayStatus));

    const baseZoom = 18;
    const currentZoom = mapRef.current.getZoom();

    // 줌 레벨에 따른 스케일 인자 계산
    const scale = Math.pow(2, baseZoom - currentZoom);

    const baseLatChange = 0.000825;
    const baseLngChange = 0.00000425;

    // 줌 레벨에 따라 변경값 조정
    const scaledLatChange = baseLatChange * scale;
    const scaledLngChange = baseLngChange * scale;
    mapRef.current.panTo(
      new naver.maps.LatLng(
        37.5827944 - scaledLatChange,
        127.0274422 - scaledLngChange
      )
    );
  };

  const clickMcampus = () => {
    // setJoojumState(
    //   filterPinsByLocationsAndDay(
    //     ["민광", "농코", "원형", "국제관", "서관", "국제관"],
    //     dayStatus
    //   )
    // );

    const baseZoom = 18;
    const currentZoom = mapRef.current.getZoom();

    // 줌 레벨에 따른 스케일 인자 계산
    const scale = Math.pow(2, baseZoom - currentZoom);

    const baseLatChange = 0.000825;
    const baseLngChange = 0.00000425;

    // 줌 레벨에 따라 변경값 조정
    const scaledLatChange = baseLatChange * scale;
    const scaledLngChange = baseLngChange * scale;
    mapRef.current.panTo(
      new naver.maps.LatLng(
        37.587067 - scaledLatChange,
        127.032055 - scaledLngChange
      )
    );
  };

  const clickShowSearhComponent = () => {
    setSearchStatus(true);
  };

  const clickCloseSearhComponent = () => {
    setSearchStatus(false);
  };

  const handleJoojumStatus = (key: string, pin: Pin) => {
    console.log("----handleJoojumStatus---", key, pin);
    setPinStatus(key);

    // setJoojumState({ [key]: pin });
    setFocusJoojum({ [key]: pin });
  };

  useEffect(() => {
    if (focusJoojum && pinStatus) {
      const item = focusJoojum[pinStatus];

      // const marker = new window.naver.maps.Marker({
      //   position: new window.naver.maps.LatLng(item.lat, item.lng),
      //   map: mapRef.current,
      //   icon: {
      //     url: urlByStatusWithPinStatus(item.status, pinStatus),
      //     size: new naver.maps.Size(30, 40),
      //     scaledSize: new naver.maps.Size(30, 40),
      //     origin: new naver.maps.Point(0, 0),
      //     anchor: new naver.maps.Point(2, 13),
      //   },
      // });

      const currentMarkerOne = currentMarker.filter((value) => {
        // @ts-ignore
        if (value.getPosition().lat() === item.lat) {
          // @ts-ignore
          return true;
        } else {
          return false;
        }
      });

      // console.log("currentMarkerOne", currentMarkerOne);

      const infoWindowClick = new window.naver.maps.InfoWindow({
        content: `,
                    <div style="width:150px;text-align:center;padding:10px;">
                        <div>${item.name}</div>
                        ${
                          item.link
                            ? `<a href=${item.link} target="_blank">👉 주점정보 바로가기</a>`
                            : ""
                        }
                    </div>`,
      });

      if (currentMarkerOne.length > 0) {
        infoWindowClick.open(mapRef.current, currentMarkerOne[0]);
      } else {
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(item.lat, item.lng),
          map: mapRef.current,
          icon: {
            url: urlByStatusWithPinStatus(item.status, pinStatus),
            size: new naver.maps.Size(30, 40),
            scaledSize: new naver.maps.Size(30, 40),
            origin: new naver.maps.Point(0, 0),
            anchor: new naver.maps.Point(2, 13),
          },
        });
        infoWindowClick.open(mapRef.current, marker);
        setCurrentMarker([marker]);
      }

      setCurrentInfoWindow(infoWindowClick);
      setDayStatus(item.day ? item.day : dayStatus);
      // setJoojumState({ [item[0]]: item[1] });
      // setFocusJoojum({ [pinStatus]: item[1] });
      setBottomSheetStatus(true);
      // setPinStatus(item[0]);
      const baseZoom = 18;
      const currentZoom = mapRef.current.getZoom();

      // 줌 레벨에 따른 스케일 인자 계산
      const scale = Math.pow(2, baseZoom - currentZoom);

      const baseLatChange = 0.000825;
      const baseLngChange = 0.00000425;

      // 줌 레벨에 따라 변경값 조정
      const scaledLatChange = baseLatChange * scale;
      const scaledLngChange = baseLngChange * scale;
      mapRef.current.panTo(
        new naver.maps.LatLng(
          item.lat - scaledLatChange,
          item.lng - scaledLngChange
        )
      );
    }
  }, [focusJoojum]);

  const decideBottomSheetHeight = () => {
    if (focusJoojum) {
      setBottomSheetHeight(window.innerHeight * 0.6);
    } else {
      setBottomSheetHeight(window.innerHeight * 0.3);
    }
    // setBottomSheetHeight(300);
  };

  return (
    <S.MapContainer>
      {searchStatus && (
        <SearchComponent
          handleJoojumStatus={handleJoojumStatus}
          setJoojumState={setFocusJoojum}
          clickCloseSearhComponent={clickCloseSearhComponent}
        />
      )}
      <S.InfoButtonContainer>
        <SearchButton onClickShowSearhComponent={clickShowSearhComponent} />

        {/* <S.InfoContainer>
          <div>🏃‍♂️ 현재 근황 입니다{eventCount}</div>
        </S.InfoContainer> */}
        {/* <S.InfoBarContainer>
          <div
            onClick={clickAllJoojum}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 주점
          </div>
        </S.InfoBarContainer> */}
        <S.InfoButtonBox>
          <S.InfoButtonDay1 active={dayStatus === 1} onClick={clickDay1Joojum}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 21일
            </div>
          </S.InfoButtonDay1>
          <S.InfoButtonDay2 active={dayStatus === 2} onClick={clickDay2Joojum}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 22일
            </div>
          </S.InfoButtonDay2>
          <S.InfoButtonDay3 active={dayStatus === 3} onClick={clickDay3Joojum}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 23일
            </div>
          </S.InfoButtonDay3>
          <S.InfoButtonDay1>
            <div
              onClick={clickEcampus}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 이캠
            </div>
          </S.InfoButtonDay1>
          <S.InfoButtonDay1>
            <div
              onClick={clickMcampus}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.ImgIcon src={`${process.env.PUBLIC_URL}/Hot.png`} /> 문캠
            </div>
          </S.InfoButtonDay1>
        </S.InfoButtonBox>
      </S.InfoButtonContainer>
      <div
        id="map"
        ref={mapRef}
        style={{ width: "100%", height: "100vh" }}
      ></div>

      {bottomSheetStatus && (
        <BottomSheet
          maxHeight={window.innerHeight * 0.6}
          currentHeight={bottomSheetHeight}
          minHeight={97}
        >
          {focusJoojum &&
          Object.entries(focusJoojum).map((item) => item).length == 1 ? (
            <Notice focusJoojum={focusJoojum} />
          ) : (
            <>
              <>
                {/* <Advertise />
                <Timeline /> */}
                {joojumState &&
                  Object.entries(joojumState)
                    .sort((a, b) => {
                      // "Cool"인 상태를 우선적으로 정렬
                      if (a[1].status === "icon" && b[1].status !== "icon")
                        return -1;
                      if (a[1].status !== "icon" && b[1].status === "icon")
                        return 1;
                      // 그 외의 경우 이름 기준으로 정렬
                      return a[1].name.localeCompare(b[1].name);
                    })
                    .map(([key, item]) => {
                      return (
                        <JoojumCard
                          link={item.link || ""}
                          handleJoojumStatus={handleJoojumStatus}
                          keyString={key}
                          item={item}
                          day={item.day || 0}
                          name={item.name}
                          location={item.location || ""}
                        />
                      );
                    })}
              </>
              <></>
            </>
          )}
        </BottomSheet>
      )}

      <SimpleBottomNavigation />
    </S.MapContainer>
  );
};

class CustomOverlay extends naver.maps.OverlayView {
  private _element: HTMLDivElement;
  private _position: any;

  constructor(options: {
    position: naver.maps.LatLng;
    map: naver.maps.Map;
    content: string;
  }) {
    super();
    this._element = document.createElement("div");
    this._element.style.position = "absolute";
    // this._element.style.width = "120px";
    // this._element.style.height = "30px";
    // this._element.style.lineHeight = "30px";
    // this._element.style.textAlign = "center";
    // this._element.style.backgroundColor = "#fff";
    // this._element.style.border = "2px solid #f00";
    // this._element.textContent = "커스텀오버레이";
    this._element.innerHTML = options.content; // HTML 내용으로 초기화
    this.setPosition(options.position);
    this.setMap(options.map);
  }

  onAdd(): void {
    const overlayLayer = this.getPanes().overlayLayer;
    overlayLayer.appendChild(this._element);
  }

  draw(): void {
    if (!this.getMap()) return;

    const projection = this.getProjection();
    const pixelPosition = projection.fromCoordToOffset(this._position);
    this._element.style.left = `${pixelPosition.x}px`;
    this._element.style.top = `${pixelPosition.y}px`;
  }

  onRemove(): void {
    this._element.parentNode?.removeChild(this._element);
  }

  setPosition(position: naver.maps.LatLng): void {
    this._position = position;
    this.draw();
  }

  getPosition(): naver.maps.LatLng {
    return this._position;
  }
}
