import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useState } from "react";
import { SimpleBottomNavigation } from "../bottomNavigation/BottomNavigation";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "시작시간",
    width: 200,
    renderCell: (params) => (
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          const isSelected = !params.row.isSelected;
          params.api.updateRows([{ id: params.id, isSelected }]);
        }}
      >
        {/* {params.row.isSelected ? (
          <NotificationsIcon color="primary" />
        ) : (
          <NotificationsNoneIcon />
        )} */}
        <span style={{ marginLeft: 8 }}>{params.value}</span>
      </Box>
    ),
  },
  {
    field: "Name",
    headerName: "name",
    width: 200,
  },
];

const rows_1 = [
  { id: "16시38분", Name: "장하영", isSelected: false },
  { id: "16시48분", Name: "라온제나", isSelected: false },
  { id: "17시16분", Name: "슈퍼소닉", isSelected: false },
  { id: "17시41분", Name: "나대나 밴드", isSelected: false },
  { id: "18시05분", Name: "ODDIE", isSelected: false },
  { id: "18시31분", Name: "1905", isSelected: false },
  { id: "18시54분", Name: "소울메이트", isSelected: false },
  { id: "19시20분", Name: "아티스트무대", isSelected: false },
];

const rows_2 = [
  { id: "16시29분", Name: "TERRA" },
  { id: "16시54분", Name: "정단비" },
  { id: "17시13분", Name: "녹두울림" },
  { id: "17시33분", Name: "NB" },
  { id: "18시00분", Name: "오칼" },
  { id: "18시23분", Name: "MOST" },
  { id: "18시45분", Name: "민지는 아이유" },
  { id: "19시07분", Name: "여섯줄 소리하나-주(酒)토피아" },
  { id: "19시32분", Name: "KUDT" },
  { id: "19시59분", Name: "삼배일보" },
  { id: "20시30분", Name: "아티스트무대" },
];

const rows_3 = [
  { id: "16시38분", Name: "연중무휴" },
  { id: "16시48분", Name: "baSIS" },
  { id: "17시16분", Name: "김동빈의 보석함" },
  { id: "17시41분", Name: "콩비" },
  { id: "18시05분", Name: "와일드아이즈" },
  { id: "18시31분", Name: "디스토션" },
  { id: "18시54분", Name: "가지튀김" },
  { id: "19시08분", Name: "아티스트무대" },
];

export default function Timeline() {
  const [day, setDay] = useState(2); // day 상태 변수
  const apiRef = useGridApiRef();

  const handleNext = () => {
    setDay((prevDay) => (prevDay === 2 ? 2 : prevDay + 1));
  };

  const handlePrevious = () => {
    setDay((prevDay) => (prevDay === 0 ? 0 : prevDay - 1));
  };

  // 현재 day에 따라 해당하는 rows 데이터를 반환하는 함수
  const allRows = () => {
    switch (day) {
      case 0:
        return rows_1;
      case 1:
        return rows_2;
      case 2:
        return rows_3;
      default:
        return [];
    }
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <DataGrid
        apiRef={apiRef}
        rows={allRows()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        pagination
        hideFooterPagination // 기본 페이지네이션 숨기기
      ></DataGrid>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "10px" }}>
        <IconButton onClick={handlePrevious} disabled={day === 0}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box sx={{ margin: "0 20px" }}>Day {day + 1}</Box>
        <IconButton onClick={handleNext} disabled={day === 2}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <SimpleBottomNavigation />
    </Box>
  );
}

//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={getCurrentRows()}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: {
//               page: 0,
//               pageSize: 5,
//             },
//           },
//         }}
//         checkboxSelection={false}
//       />
//     </div>
//   );
// }

//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={rows_1}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: {
//               page: 0,
//               pageSize: 5,
//             },
//           },
//         }}
//         checkboxSelection={false}
//       />
//     </div>
//   );
// }
// const columns: GridColDef[] = [
//   { field: "id", headerName: "시작시간", width: 70 },
//   { field: "Name", headerName: " name", width: 130 },
// ];

// const rows_1 = [
//   { id: "16시38분", Name: "장하영" },
//   { id: "16시48분", Name: "라온제나" },
//   { id: "17시16분", Name: "슈퍼소닉" },
//   { id: "17시41분", Name: "나대나 밴드" },
//   { id: "18시05분", Name: "ODDIE" },
//   { id: "18시31분", Name: "1905" },
//   { id: "18시54분", Name: "소울메이트" },
//   { id: "19시20분", Name: "아티스트무대" },
// ];

// export default function Timeline() {
//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={rows_1}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: { page: 0, pageSize: 5 },
//           },
//         }}
//         pageSizeOptions={[5, 10]}
//         checkboxSelection={false}
//       />
//     </div>
//   );
// }
