export enum PathUrl {
  Home = "/",
  Timeline = "/timeline",
  MyZiphap = "/",
  Login = "/login",
  Logout = "/logout",
  Other = "/other",
  History = "/history",
  AddEvent = "/add-event",
  AddGroup = "/add-group",
  AddName = "/add-name",
  Comment = "/comment",
  Bar = "/bar",
  Map = "/map",
  AddFestival = "/add-festival",
  Joojum = "/joojum",
}
